import { Fragment, useState, useEffect, useContext, useRef} from 'react';

const REVEAL_MS = 200;
const REVEAL_OFFSET = 40;
export const RevealBlock = (props) => {
  const {
    visible,
    delay = 0,
    style = {}
  } = props;

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState(REVEAL_OFFSET);

  useEffect(()=>{
    setTimeout(()=>{
      if(visible){
        setOpacity(1);
        setOffset(0);
      }
      else{
        setOpacity(0);
        setOffset(-1*REVEAL_OFFSET);
        setTimeout(()=>{
          setOffset(REVEAL_OFFSET);
        }, REVEAL_MS);
      }  
    }, delay);
  }, [visible]);

  return(
    <div
      style={{
        opacity: opacity,
        transform: `translate(0px, ${offset}px)`,
        transition: `opacity ${REVEAL_MS*.5}ms ease, transform ${REVEAL_MS}ms ease`,
        ...style
      }}>
        {props.children}
    </div>
  );
}


export const Button = (props) => {
   const [buttonOpacity, setButtonOpacity] = useState(1);
   const {
      style = {},
      size = '',
      cursor,
      className = ''
   } = props;
   
   const onMouseDown = () => {
      setButtonOpacity(0.75);
   }
   useEffect(()=>{
      setButtonOpacity(1);
   }, [cursor]);

   return(
      <div 
         style={{opacity: buttonOpacity, ...style}}
         onClick={props.onClick}
         onMouseDown={onMouseDown}
         onTouchStart={onMouseDown}
         className={`button ${size === 'small' ? 'buttonSmall' : ''} ${className}`}
         >
         {props.children}
      </div>
   );
}

export const ScoreDisplay = (props) => {
   const {score} = props;
   const [displayScore, setDisplayScore] = useState(0);
   useEffect(()=>{
      setTimeout(()=>{
         setDisplayScore(score)
      }, 800);
   }, [props.score]);
   return(
      <div className={`score ${displayScore > 0 ? 'goodScore' : 'badScore'}`}>
         <span style={{fontSize: 16}}>🏅 </span>{displayScore} {displayScore == 1 ? 'point' : 'points'}
      </div>
   );
}


export const Header = (props) => {
   const [hasShare, setHasShare] = useState(false);

   const shareIt = async (shareData) => {
      if (navigator.share) {
        try {
          await navigator.share(shareData)
        } catch (err) {
          console.error(err)
        }
      }
    }

    useEffect(()=>{
      if (navigator.share) {
         setHasShare(true);
      }
    }, []);


   return(
      <div className="header" style={{flex: 0}}>
         
            <div style={{marginTop: 0, marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
               <a href="/">
               <div style={{display: 'flex', flex: 0, alignItems: 'center'}} className="clickable">
                  <img src="logo.svg" style={{height: 24}} />
               </div>
               </a>
               <div style={{flex: 1}} />
               <div style={{flex: 0}}>
                  
                     {ScoreDisplay(props)}
          
               </div>
            </div>
        </div>
   )
}


export const Layout = (props) => {
   // bottom bar hack
   const setDocumentHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
   }
   useEffect( () =>{
      window.addEventListener('resize', setDocumentHeight);
      setDocumentHeight();
      return _ => {
      window.removeEventListener('resize', setDocumentHeight);
      }
   }, [])

   return(
      <Fragment>
         {/*
         <Head>
            <title>Whichipedia {(props.pageTitle) ? ` - ${props.pageTitle}` : ''}</title>
            <meta name="description" content="Which Wikipedia article is longer?" />
            <meta name="twitter:card" content="summary" />
            <meta property="og:title" content="Whichipedia" />
            <meta property="og:description" content="Which Wikipedia article is longer?" />
            <meta property="twitter:image" content="" />
            <meta property="og:image" content="" />
            <meta property="og:type" content="website" />
            <link rel="icon" href="/favicon.ico" />
         </Head>
         */}
         <div className="outer">
            <div className="outerPadding bounds">
            <Header score={props.score} />
            <div className="main" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
               {props.children}
            </div>
            </div>
         </div>
      </Fragment>
   )

}